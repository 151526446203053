import { useEffect, useState } from "react";
import { stripHtml } from "string-strip-html";
import { useDispatch } from "store";
import { RSSFeed, RSSFeedItem, UUID } from "types";
import { Heading, Text } from "components/shared/typography";
import { fetchPolicyRSSFeedByID } from "store/reducers";
import { LoadingIcon } from "components/shared/icons";
import { List, ListItem } from "components/shared";

type PolicyNewsFeedProps = {
  policyId: UUID;
};

export const PolicyNewsFeed = ({ policyId }: PolicyNewsFeedProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState<RSSFeedItem[]>([]);

  useEffect(() => {
    fetchFeed();
  }, []);

  const fetchFeed = () => {
    setLoading(true);
    dispatch(fetchPolicyRSSFeedByID(policyId))
      .unwrap()
      .then((data: RSSFeed) => {
        setArticles(data.items);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingIcon />;
  }

  return (
    <List>
      {articles.slice(0, 5).map((article) => (
        <ListItem key={article.link}>
          <a href={article.link} target="_blank">
            <Heading textColor="text-gold-700">{article.title}</Heading>
          </a>
          <Text>
            {stripHtml(article.content || article.description).result}
          </Text>
        </ListItem>
      ))}
    </List>
  );
};
