import React, { ChangeEvent, FormEvent, useState } from "react";
import { useModal } from "hooks/useModal";
import {
  CoreMetricSelector,
  InputGroup,
  PrimaryButton,
  TextInput,
  WhiteButton,
} from "components/shared";
import { useDispatch } from "store";
import { createObjective, openPopupFlag } from "store/reducers";
import { CreateObjectiveRequest } from "types";
import { Modal } from "components/shared/modals";

type CreateObjectiveModalProps = {
  onSuccess?: () => void;
};

export const CreateObjectiveModal = ({
  onSuccess,
}: CreateObjectiveModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [values, setValues] = useState<CreateObjectiveRequest>({
    name: "",
    description: "",
  });

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    dispatch(createObjective(values))
      .unwrap()
      .then((data) => {
        setValues(data);
        dispatch(
          openPopupFlag({
            appearance: "success",
            title: "Objective created",
          })
        );
      });
    closeModal();
    onSuccess?.();
  };

  const handleChange = (name: keyof CreateObjectiveRequest) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      setValues({
        ...values,
        [name]: event.target.value,
      });
    };
  };

  const handleSelectorChange = (
    name: keyof CreateObjectiveRequest,
    value: any
  ) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div className="p-4 h-screen w-screen flex flex-col items-center justify-center">
      <form onSubmit={handleSubmit} className="px-1">
        <Modal>
          <Modal.Header title="Create a objective" onClose={closeModal} />
          <div className="flex flex-col flex-1">
            <InputGroup label="Name">
              <TextInput
                name="name"
                placeholder="Enter objective name"
                value={values.name}
                onChange={handleChange("name")}
              />
            </InputGroup>
            <InputGroup label="Description">
              <TextInput
                name="description"
                placeholder="Enter description of the objective"
                value={values.description}
                onChange={handleChange("description")}
              />
            </InputGroup>
            <InputGroup label="Key metric (optional)">
              <CoreMetricSelector
                label="Select a key metric"
                onSelect={(value) =>
                  handleSelectorChange("coreMetricId", value?.id)
                }
                value={values.coreMetricId}
                filterCoreMetrics={
                  values.coreMetricId ? [values.coreMetricId] : undefined
                }
              />
            </InputGroup>
          </div>
          <Modal.Footer>
            <PrimaryButton type="submit">Create</PrimaryButton>
            <WhiteButton onClick={closeModal}>Cancel</WhiteButton>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
};
