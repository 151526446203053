import classNames from "classnames";

export enum CreatePolicyModalStep {
  Objective = 1,
  KeyResults = 2,
  Interventions = 3,
}

type CreatePolicyModalStepsProps = {
  step: CreatePolicyModalStep;
  onStepChange: (step: CreatePolicyModalStep) => void;
};

export const CreatePolicyModalSteps = ({
  step,
  onStepChange,
}: CreatePolicyModalStepsProps) => {
  const options = [
    {
      progress: 15,
      label: "Set objective",
      value: CreatePolicyModalStep.Objective,
    },
    {
      progress: 50,
      label: "Set key results",
      value: CreatePolicyModalStep.KeyResults,
    },
    {
      progress: 85,
      label: "Pick solutions",
      value: CreatePolicyModalStep.Interventions,
    },
  ];

  const calculateProgress = () => {
    const option = options.find((option) => option.value === step);
    return option ? `${option.progress}%` : "0%";
  };

  return (
    <div>
      <div className="mt-2">
        <div className="bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-2 bg-zinc-600 rounded-full"
            style={{ width: calculateProgress() }}
          />
        </div>
        <div className="grid grid-cols-3 text-sm font-medium text-gray-400 mt-6">
          {options.map((option) => {
            return (
              <div
                key={option.value}
                className={classNames(
                  {
                    ["text-zinc-600"]: step >= option.value,
                    ["text-center"]: true,
                  },
                  "cursor-pointer"
                )}
                onClick={() => onStepChange(option.value)}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
