import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "store";
import {
  fetchPolicies,
  selectPolicies,
  selectPoliciesList,
} from "store/reducers";
import { ComponentAppearance, Policy, UUID } from "types";
import { RoutePath } from "routes/RoutePath";
import {
  LoadingIcon,
  PolicyTableRow,
  TransparentButton,
} from "components/shared";

type PolicyTableProps = {
  ids?: UUID[];
  coreMetricIds?: UUID[];
  maxRows?: number;
  appearance?: ComponentAppearance;
};

export const PolicyTable = ({
  coreMetricIds = [],
  maxRows,
  appearance = "full",
}: PolicyTableProps) => {
  const dispatch = useDispatch();
  const policiesList = useSelector(selectPoliciesList);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchPolicies({ coreMetricIds })).finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="mt-2">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <>
      <table className="">
        {appearance === "full" && (
          <thead>
            <tr className="border-t border-gray-200 bg-gray-50">
              <th className="pl-6 py-3 border-b border-gray-20  text-xs font-medium text-gray-500 uppercase tracking-wider text-center w-40">
                Progress
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Policy
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider text-left">
                Solutions
              </th>
              <th className="hidden md:table-cell px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Dates
              </th>
              <th className="hidden md:table-cell px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" />
            </tr>
          </thead>
        )}
        <tbody>
          {(maxRows ? policiesList.slice(0, maxRows) : policiesList).map(
            (policy) => {
              return (
                <PolicyTableRow
                  key={policy.id}
                  policyId={policy.id}
                  appearance={appearance}
                />
              );
            }
          )}
        </tbody>
      </table>
      {maxRows && policiesList.length > maxRows && (
        <div className="flex flex-row">
          <a href="#policies">
            <TransparentButton
              textColor="text-white"
              textHoverColor="hover:text-gray-700"
            >
              View more policies
            </TransparentButton>
          </a>
        </div>
      )}
    </>
  );
};
