import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { LoginRequest, LoginResponse } from "types";

export const login = createAsyncThunk(
  "accounts/login",
  async (request: LoginRequest): Promise<LoginResponse> => {
    return await API.POST<LoginRequest, LoginResponse>("/signin", request);
  }
);
