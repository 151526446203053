import { UUID } from "types";
import { useSelector } from "store";
import {
  selectCoreMetricByID,
  selectKeyPerformanceMetricsList,
} from "store/reducers";
import { LoadingIcon } from "components/shared/icons";
import { FlexContainer } from "components/shared/layouts";
import { CoreDashboardMetricPoliciesPopover } from "components/shared/features/coreDashboardMetrics/CoreDashboardMetricPoliciesPopover/CoreDashboardMetricPoliciesPopover";
import { CDMChart } from "components/shared/charts";

type CoreDashboardMetricProps = {
  coreMetricId: UUID;
};

export const CoreDashboardMetric = ({
  coreMetricId,
}: CoreDashboardMetricProps) => {
  const coreMetric = useSelector(selectCoreMetricByID(coreMetricId));
  const metricsList = useSelector(selectKeyPerformanceMetricsList);
  const metrics = metricsList.filter(
    (metric) => metric.coreMetricId === coreMetricId
  );
  const totalWeights = metrics.reduce((totalWeight, metric) => {
    return totalWeight + metric.weight;
  }, 0);
  const weights = metrics.reduce((totalValue, metric) => {
    return totalValue + metric.normalisedValue * (metric.weight / 100);
  }, 0);

  if (!coreMetric || !metricsList.length) {
    return <LoadingIcon />;
  }

  return (
    <FlexContainer alignItems="items-center">
      <CoreDashboardMetricPoliciesPopover coreMetricId={coreMetricId}>
        <CDMChart
          label={coreMetric.name}
          value={(weights * 100) / totalWeights}
        />
      </CoreDashboardMetricPoliciesPopover>
    </FlexContainer>
  );
};
