import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "store";
import { selectAuthSession } from "store/reducers";
import {
  FlexContainer,
  PolicyTable,
  SimpleGlobe,
  Title,
  Heading,
  CoreDashboardMetrics,
  InteractiveGlobe,
  PoliciesRatingSummary,
  NewsfeedBar,
} from "components/shared";
import logo from "assets/images/ALC_PI_Logo_Large_white.png";
import { WellbeingDashboardMetric } from "components/shared";
import { PoliciesPage, SettingsPage } from "pages";

const HomePageSections = () => {
  return (
    <>
      <NewsfeedBar />
      <FlexContainer
        direction="flex-col"
        width="w-full"
        position="relative"
        flex="flex-1"
        pointerEvents="pointer-events-none"
      >
        <div className="mb-96 mt-10 min-h-screen">
          <div>
            <div className="mb-3 pointer-events-auto">
              <div className="flex-shrink-0 mb-2">
                <img className="block h-20" src={logo} alt="Workflow" />
              </div>
              <Title textColor="text-white">Your Impact Dashboard</Title>
            </div>
            <div className="relative max-w-screen2/3 lg:max-w-screen1/2 w-full grid grid-cols-metrics gap-4 mb-5 pointer-events-auto justify-center">
              <div className="flex flex-col justify-center">
                <WellbeingDashboardMetric />
              </div>
              <CoreDashboardMetrics />
            </div>
          </div>
          <div className="max-w-screen1/3 text-white pointer-events-auto">
            <Heading textColor="text-white">Active projects</Heading>
            <PolicyTable maxRows={3} appearance="compact" />
          </div>
        </div>
      </FlexContainer>

      <FlexContainer
        direction="flex-col"
        width="w-full"
        position="relative"
        flex="flex-1"
      >
        <div className="mb-96 min-h-screen">
          <PoliciesPage />
        </div>
      </FlexContainer>

      <FlexContainer
        minHeight="min-h-screen"
        direction="flex-col"
        width="w-full"
        position="relative"
        flex="flex-1"
        alignItems="items-center"
      >
        <div className="w-full min-h-screen">
          <SettingsPage />
        </div>
      </FlexContainer>
    </>
  );
};

export const HomePage = () => {
  const navigate = useNavigate();
  const session = useSelector(selectAuthSession);
  const [interactive, setInteractive] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    if (!session) {
      navigate("/logout", { replace: true });
    }
  }, [session]);

  return (
    <>
      <div
        className={classNames(
          "transition-opacity duration-300 ease-in-out opacity-0 delay-[2000ms]",
          ready ? "opacity-100" : ""
        )}
      >
        <div
          className={classNames(
            "fixed top-0 w-screen h-screen transition-all duration-300 ease-in-out duration-300",
            interactive ? "right-0 delay-300" : "-right-1/3"
          )}
        >
          {interactive ? (
            <InteractiveGlobe onClose={() => setInteractive(false)} />
          ) : (
            <div className="flex h-full items-center">
              <PoliciesRatingSummary onEnterPI={() => setInteractive(true)} />
              <SimpleGlobe />
            </div>
          )}
        </div>
      </div>
      <Transition
        show={!interactive && ready}
        className="opacity-0"
        enter="transition ease-in-out duration-500 delay-700"
        entered="opacity-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <HomePageSections />
      </Transition>
    </>
  );
};
