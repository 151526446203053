import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import {
  DashboardLayout,
  HomePage,
  NotFoundPage,
  PolicyLayout,
  PolicyPage,
  SolutionSettingsPage,
} from "pages";
import { RoutePath } from "routes/RoutePath";
import { useDispatch, useSelector } from "store";
import {
  fetchCoreMetrics,
  fetchMetrics,
  fetchObjectives,
  fetchSimulations,
  fetchSolutions,
  selectAuthSession,
} from "store/reducers";

export const AppRouter = () => {
  const dispatch = useDispatch();
  const session = useSelector(selectAuthSession);

  useEffect(() => {
    let intervalIDs: any[] = [];
    if (session) {
      dispatch(fetchObjectives());
      dispatch(fetchSolutions());
      dispatch(fetchCoreMetrics());
      dispatch(fetchMetrics());
      dispatch(fetchSimulations());

      intervalIDs.push(
        setInterval(() => {
          dispatch(fetchSimulations());
        }, 500),
        setInterval(() => {
          dispatch(fetchMetrics());
        }, 1000)
      );
    }
    return () => {
      intervalIDs.forEach((id) => clearInterval(id));
    };
  }, [dispatch, session]);

  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path={`${RoutePath.dashboard.root}*`} element={<HomePage />} />
      </Route>
      <Route element={<PolicyLayout />}>
        <Route path={RoutePath.policies.policy} element={<PolicyPage />} />
      </Route>
      <Route
        path={RoutePath.solutions.solution}
        element={
          <div className="flex justify-center">
            <div className="bg-white max-w-3xl w-full rounded-lg p-5 m-5">
              <SolutionSettingsPage />
            </div>
          </div>
        }
      />
      <Route
        path="/"
        element={
          <Navigate to={session ? RoutePath.dashboard.root : RoutePath.login} />
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
