import { Button, GenericButtonProps } from "components/shared/buttons";

export type PrimaryButtonProps = GenericButtonProps & {};

export const PrimaryButton = ({ ...otherProps }: PrimaryButtonProps) => {
  return (
    <Button
      background="bg-gold-700"
      textHoverColor="hover:text-white"
      backgroundHover="hover:bg-gold-600"
      ringFocusColor="focus:ring-gold-700"
      {...otherProps}
    />
  );
};
