import React, { createContext, Fragment, ReactNode, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Portal } from "@reach/portal";
import {
  KEY_CODE_ESC,
  KeyHandler,
} from "components/shared/cms/KeyHandler/KeyHandler";

type ModalProviderProps = {
  children: ReactNode;
};

type ModalContextProperties = {
  openModal: (component: ReactNode) => void;
  closeModal: () => void;
};

export const ModalContext = createContext<ModalContextProperties>({
  openModal: () => {},
  closeModal: () => {},
});

type State = {
  modals: ReactNode[];
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [state, setState] = useState<State>({
    modals: [],
  });

  const openModal = (modal: ReactNode): void => {
    setState((prevState) => {
      return {
        modals: [...prevState.modals, modal],
      };
    });
  };

  const closeModal = (): void => {
    setState((prevState) => {
      return {
        modals: prevState.modals.slice(0, prevState.modals.length - 1),
      };
    });
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      {children}
      {state.modals.length !== 0 && (
        <KeyHandler keyCode={KEY_CODE_ESC} onKeyHandle={closeModal} />
      )}
      {state.modals.map((Modal, index) => {
        return (
          <Portal key={`portal_${index}`}>
            <Transition.Root appear show as={Fragment}>
              <Dialog
                className="fixed inset-0 overflow-y-auto"
                style={{ zIndex: 20 + index }}
                onClose={() => null}
              >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div
                      onClick={closeModal}
                      className="fixed inset-0 bg-gray-500 bg-opacity-95 transition-opacity"
                    />
                  </Transition.Child>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <div>{Modal}</div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
          </Portal>
        );
      })}
    </ModalContext.Provider>
  );
};
