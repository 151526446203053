import { Button, GenericButtonProps } from "components/shared/buttons";

export type WhiteButtonProps = GenericButtonProps & {};

export const WhiteButton = ({ ...otherProps }: WhiteButtonProps) => {
  return (
    <Button
      background="bg-white"
      textColor="text-gray-900"
      backgroundHover="hover:bg-gray-50"
      ringFocusColor="focus:ring-gray-300"
      borderColor=""
      {...otherProps}
    />
  );
};
