import { useDispatch } from "store";
import { useEffect, useState } from "react";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { AutoPlay } from "@egjs/flicking-plugins";
import dayjs from "dayjs";
import styled from "styled-components";
import { RSSFeed, RSSFeedItem } from "types";
import { fetchPolicyRSSFeedByID } from "store/reducers";
import { LoadingIcon, Text } from "components/shared";

const Panel = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 2.5rem;
  cursor: pointer;
`;

export const NewsfeedBar = () => {
  const dispatch = useDispatch();
  const plugins = [
    new AutoPlay({ duration: 5000, direction: "NEXT", stopOnHover: true }),
  ];
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState<RSSFeedItem[]>([]);

  useEffect(() => {
    fetchFeed();
  }, []);

  const fetchFeed = () => {
    setLoading(true);
    dispatch(fetchPolicyRSSFeedByID("123"))
      .unwrap()
      .then((data: RSSFeed) => {
        setArticles(data.items);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="fixed left-0 right-0 top-0 z-10">
      <div className="bg-ocean-900/80 h-10 flex items-center justify-center text-sm font-medium text-white px-4 sm:px-6 lg:px-8 overflow-hidden">
        {loading ? (
          <LoadingIcon />
        ) : (
          <Flicking circular plugins={plugins} horizontal={false}>
            {articles.map((article) => (
              <Panel>
                <a
                  href={article.link}
                  target="_blank"
                  className="hover:underline"
                >
                  <Text>
                    {dayjs(article.publishedParsed).format("LL")}
                    {": "}
                    {article.title}
                  </Text>
                </a>
              </Panel>
            ))}
          </Flicking>
        )}
      </div>
    </div>
  );
};
