import React, { useEffect, useState } from "react";
import { Listbox, Portal } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import dayjs from "dayjs";
import { usePopper } from "react-popper";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import { Policy } from "types";

type PolicyDurationInputOption = {
  id: number;
  name: string;
};

type PolicyDurationInputProps = {
  value: Policy;
  onChange: (value: Policy) => void;
};

export const PolicyDurationInput = ({
  value,
  onChange,
}: PolicyDurationInputProps) => {
  const { search } = useLocation();
  const { timescale } = parse(search);
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {});

  const options = new Array(12 * 5).fill(null).map((_, index) => ({
    id: index + 1,
    name: `${index + 1} months`,
  }));
  const [selected, setSelected] = useState<PolicyDurationInputOption>(
    options[timescale ? parseInt(timescale as string) - 1 : 5]
  );

  useEffect(() => {
    if (selected) {
      onChange({
        ...value,
        dateEnd: dayjs().add(selected.id, "month").endOf("month").toISOString(),
      });
    } else {
      onChange({
        ...value,
        dateEnd: "",
      });
    }
  }, [selected]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative inline-block w-48">
            <Listbox.Button
              ref={setReferenceElement}
              className="bg-white w-full relative border focus:outline-none focus:ring-zinc-400 focus:border-zinc-400 focus:ring-1 rounded-md shadow-sm pl-3 pr-10 py-2 text-left sm:text-sm"
            >
              <span className="block truncate">
                {selected ? selected.name : ""}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Portal>
              <Listbox.Options
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className="z-30 w-40 mt-3 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-gold-700" : "text-gray-900",
                        "text-left cursor-pointer relative py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-gold-700",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Portal>
          </div>
        </>
      )}
    </Listbox>
  );
};
