import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { parse } from "query-string";
import { PlusIcon } from "@heroicons/react/solid";
import { useModal } from "hooks/useModal";
import {
  CreatePolicyModal,
  FlexContainer,
  PolicyTable,
  PrimaryButton,
} from "components/shared";
import { useLazyGetDecisionQuery } from "features/decisions";

export const PoliciesPage = () => {
  const { openModal } = useModal();
  const { search } = useLocation();
  const [getDecision, { data }] = useLazyGetDecisionQuery();

  useEffect(() => {
    const { action, decisionId } = parse(search);
    if (action === "create_policy") {
      openModal(<CreatePolicyModal />);
      return;
    }
    if (decisionId) {
      getDecision(decisionId as string);
    }
  }, [search]);

  useEffect(() => {
    if (!data) return;
    openModal(<CreatePolicyModal decision={data} />);
  }, [data]);

  const createPolicy = () => {
    openModal(<CreatePolicyModal />);
  };

  return (
    <>
      <header className="py-10">
        <FlexContainer justify="justify-between">
          <h1 id="policies" className="text-3xl font-bold text-white">
            Projects
          </h1>
          <div className="ml-5">
            <PrimaryButton onClick={createPolicy} leadingIcon={PlusIcon}>
              Create project
            </PrimaryButton>
          </div>
        </FlexContainer>
      </header>
      <div className="bg-white overflow-hidden rounded-lg shadow">
        <div className="align-middle inline-block min-w-full border-b border-gray-200">
          <PolicyTable />
        </div>
      </div>
    </>
  );
};
