import { Text, Title } from "components/shared/typography";
import { FlexContainer } from "components/shared/layouts";
import { PrimaryButton } from "components/shared/buttons";
import { useEffect, useRef, useState } from "react";

type PoliciesRatingSummaryProps = {
  onEnterPI: () => void;
};

export const PoliciesRatingSummary = ({
  onEnterPI,
}: PoliciesRatingSummaryProps) => {
  const [opacity, setOpacity] = useState(1);
  const scrollHandler = () => {
    const scrollTop = document.documentElement.scrollTop;
    const screenHeight = window.innerHeight / 2;
    const progress = screenHeight - scrollTop;
    const percentage = progress / screenHeight;
    setOpacity(percentage < 0 ? 0 : percentage);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <div
      style={{ opacity }}
      className="flex bg-ocean-900/80 flex-col text-center z-10 fixed right-14 rounded-lg p-5 w-96 items-center"
    >
      <Title textColor="text-white" fontWeight="font-thin">
        Your impact at a glance
      </Title>
      <div className="mt-2">
        <p className="text-white/80 mt-1 text-[12px]">
          Your dashboard measures the impact of your
          objectives in the real world and generates a score
          measured in tonnes of carbon.
        </p>
      </div>
      <div className="flex flex-row gap-5 mt-5">
        <FlexContainer
          direction="flex-col"
          justify="justify-center"
          alignItems="items-center"
        >
          <Text
            textSize="text-4xl"
            fontWeight="font-bold"
            textColor="text-white"
          >
            256.38t
          </Text>
          <p className="text-white/80 text-[12px]">CO2 Equivalent</p>
        </FlexContainer>
        <FlexContainer
          direction="flex-col"
          justify="justify-center"
          alignItems="items-center"
        >
          <Text
            textSize="text-4xl"
            fontWeight="font-bold"
            textColor="text-white"
          >
            A-
          </Text>
          <p className="text-white/80 text-[12px]">Sustainability Rating</p>
        </FlexContainer>
      </div>
      <div className="mt-5 pointer-events-auto">
        <PrimaryButton onClick={onEnterPI}>Enter planet</PrimaryButton>
      </div>
    </div>
  );
};
