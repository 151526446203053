import { Menu, Portal } from "@headlessui/react";
import React, { useState } from "react";
import {
  ArchiveIcon,
  DuplicateIcon,
  PencilIcon,
  SaveIcon,
  TrashIcon,
  PauseIcon,
  AdjustmentsIcon,
} from "@heroicons/react/outline";
import { usePopper } from "react-popper";
import { SecondaryButton } from "components/shared/buttons";
import { deletePolicy } from "store/reducers";
import { UUID } from "types";
import { useDispatch } from "store";

const MenuItem = ({ icon: Icon, label, onClick }: Action) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={`${
            active ? "bg-gold-700 text-white" : "text-gray-900"
          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
          onClick={onClick}
        >
          <Icon className="w-5 h-5 mr-2" aria-hidden="true" />
          {label}
        </button>
      )}
    </Menu.Item>
  );
};

type Action = {
  label: string;
  icon: any;
  onClick?: () => void;
};

type PolicyTableRowDropdownProps = {
  policyId: UUID;
};

export const PolicyTableRowDropdown = ({
  policyId,
}: PolicyTableRowDropdownProps) => {
  const dispatch = useDispatch();
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [5, 5],
        },
      },
    ],
  });
  const actions: Action[][] = [
    [
      {
        label: "Pause",
        icon: PauseIcon,
      },
      {
        label: "Edit",
        icon: PencilIcon,
      },
    ],
    [
      {
        label: "Duplicate",
        icon: DuplicateIcon,
      },
      {
        label: "Archive",
        icon: ArchiveIcon,
      },
      {
        label: "Export",
        icon: SaveIcon,
      },
    ],
    [
      {
        label: "Delete",
        icon: TrashIcon,
        onClick: () => {
          dispatch(deletePolicy(policyId));
        },
      },
    ],
  ];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div ref={setReferenceElement}>
        <Menu.Button as={SecondaryButton} leadingIcon={AdjustmentsIcon}>
          Options
        </Menu.Button>
      </div>
      <Portal>
        <Menu.Items
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          {actions.map((group, index) => (
            <div key={`group_${index}`} className="px-1 py-1">
              {group.map(({ icon, label, onClick }) => (
                <MenuItem
                  key={`groupitem_${label}`}
                  icon={icon}
                  label={label}
                  onClick={onClick}
                />
              ))}
            </div>
          ))}
        </Menu.Items>
      </Portal>
    </Menu>
  );
};
