//@ts-nocheck
import { useCallback, useState } from "react";
import Globe from "react-globe.gl";
import "./SimpleGlobe.module.scss";

type SimpleGlobeProps = {
  onClick?: () => void;
};

export const SimpleGlobe = ({ onClick }: SimpleGlobeProps) => {
  const globeImageURL =
    "https://peoplesmart-website.s3.eu-west-2.amazonaws.com/images/globe/world.200412.3x5400x2700.jpg";
  const bumpImage =
    "https://peoplesmart-website.s3.eu-west-2.amazonaws.com/images/globe/globe_bump_5400x2700.png";
  const globeImageLargeURL =
    "https://peoplesmart-website.s3.eu-west-2.amazonaws.com/images/globe/world.200412.3x5400x2700.jpg";
  const [image, setImage] = useState(globeImageURL);
  const [initialised, setInitialised] = useState(false);
  const globeRef = useCallback(
    (node) => {
      if (node && !initialised) {
        setInitialised(true);
        node.pointOfView(
          {
            lat: 48.8566,
            lng: 2.3522,
            altitude: 1,
          },
          500
        );
        node.controls().enableZoom = false;
        node.controls().autoRotate = true;
        node.controls().autoRotateSpeed = 0.7;
      }
    },
    [initialised]
  );

  const handleZoom = (value: any) => {
    if (!value) return;
    if (value.altitude < 0.7) {
      setImage(globeImageLargeURL);
    } else {
      setImage(globeImageURL);
    }
  };

  return (
    <Globe
      ref={globeRef}
      globeImageUrl={image}
      bumpImageUrl={bumpImage}
      onGlobeClick={onClick}
      onZoom={handleZoom}
    />
  );
};
