import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CreateObjectiveRequest,
  DataResponse,
  GetObjectivesResponse,
  Objective,
  UpdateObjectiveRequest,
} from "types";
import { API } from "lib/API";

export const fetchObjectives = createAsyncThunk(
  "objectives/list",
  async (): Promise<Objective[]> => {
    const { data } = await API.GET<GetObjectivesResponse>("/objectives", {
      pageLimit: 10_000,
    });
    return data;
  }
);

export const createObjective = createAsyncThunk(
  "objectives/create",
  async (request: CreateObjectiveRequest): Promise<Objective> => {
    const { data } = await API.POST<
      CreateObjectiveRequest,
      DataResponse<Objective>
    >("/objectives", request);
    return data;
  }
);

export const updateObjective = createAsyncThunk(
  "objectives/update",
  async (request: UpdateObjectiveRequest): Promise<Objective> => {
    const { data } = await API.PUT<
      UpdateObjectiveRequest,
      DataResponse<Objective>
    >(`/objectives/${request.id}`, request);
    return data;
  }
);
