import {
  CreateObjectiveModal,
  FlexContainer,
  ObjectivesTable,
  PrimaryButton,
} from "components/shared";
import { useModal } from "hooks/useModal";

export const ObjectivesSettingsPage = () => {
  const { openModal } = useModal();

  const createObjective = () => openModal(<CreateObjectiveModal />);

  return (
    <div className="max-w-3xl mx-auto py-10 px-4">
      <FlexContainer justify="justify-between">
        <h1 className="text-3xl font-extrabold text-blue-gray-900 mb-10">
          Objectives
        </h1>
        <div>
          <PrimaryButton onClick={createObjective}>Create</PrimaryButton>
        </div>
      </FlexContainer>
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <ObjectivesTable />
      </div>
    </div>
  );
};
