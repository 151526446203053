import { useMemo } from "react";
import { v4 as uuid } from "uuid";
import { Initiative, Policy, Solution, UUID } from "types";
import {
  PolicySentence,
  FlexContainer,
  SolutionListItem,
  SolutionSelector,
} from "components/shared";
import { useSelector } from "store";
import { selectKeyPerformanceMetrics } from "store/reducers";

type InitiativeListProps = {
  policy: Policy;
  initiatives: Initiative[];
  metricIds?: UUID[]; // Metric IDs to resolve metrics and then core metrics.
  disabled?: boolean;
  onCreate?: (value: Initiative) => void;
  onRemove?: (value: Initiative) => void;
};

export const InitiativeList = ({
  policy,
  metricIds,
  initiatives,
  disabled,
  onCreate,
  onRemove,
}: InitiativeListProps) => {
  const metrics = useSelector(selectKeyPerformanceMetrics);
  const focusOnCoreMetrics = useMemo(() => {
    if (!metricIds) {
      return [];
    }
    const activeCoreMetrics = metricIds
      .map((metricId) => {
        return metrics[metricId].coreMetricId;
      })
      .reduce((values: any, coreMetricId: UUID | undefined) => {
        if (!coreMetricId) {
          return { ...values };
        }
        return {
          ...values,
          [coreMetricId]: coreMetricId,
        };
      }, {});
    return Object.keys(activeCoreMetrics);
  }, [metricIds]);

  const addItem = (value: Solution) => {
    const newValue: Initiative = {
      id: uuid(),
      policyId: "",
      solutionId: value.id,
      dateCreated: "",
      dateUpdated: "",
    };
    if (onCreate) {
      onCreate(newValue);
    }
  };

  return (
    <div className="py-5 px-1">
      <div className="bg-white mb-2 rounded-md">
        <div className="mb-5">
          <h3 className="text-md leading-6 font-medium text-gray-900">
            Solutions
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Pick solutions that will impact <PolicySentence policy={policy} />
          </p>
        </div>
        <ul role="list">
          {initiatives.map((initiative) => {
            return (
              <div key={initiative.id}>
                <SolutionListItem
                  disabled={disabled}
                  solutionId={initiative.solutionId}
                  onRemove={() => onRemove && onRemove(initiative)}
                />
              </div>
            );
          })}
          {!disabled && Object.values(initiatives).length === 0 && (
            <li className="block">
              <FlexContainer justify="justify-start" alignItems="items-center">
                <SolutionSelector
                  label="Choose solution"
                  selected={initiatives.map((value) => value.solutionId)}
                  coreMetricIds={focusOnCoreMetrics}
                  onSelect={addItem}
                />
              </FlexContainer>
            </li>
          )}
        </ul>
      </div>
      {!disabled && Object.values(initiatives).length > 0 && (
        <FlexContainer marginTop="mt-5">
          <SolutionSelector
            label="Choose solution"
            selected={initiatives.map((value) => value.solutionId)}
            coreMetricIds={focusOnCoreMetrics}
            onSelect={addItem}
          />
        </FlexContainer>
      )}
    </div>
  );
};
