import { Chart } from "components/shared/charts/Chart/Chart";
import { ApexOptions } from "apexcharts";

type CDMChartProps = {
  label: string;
  value: number;
  height?: number;
};

export const CDMChart = ({ label, value, height = 130 }: CDMChartProps) => {
  const onClick = (event: MouseEvent) => {};

  const series = [value];
  const options: ApexOptions = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 0,
          background: "#000011",
        },
        dataLabels: {
          name: {
            fontSize: "14px",
            color: "white",
            offsetY: 55,
          },
          value: {
            offsetY: -7,
            fontSize: "1.25rem",
            fontWeight: 600,
            color: "white",
            formatter: function (val) {
              if (val >= 94) {
                return "A";
              }
              if (val >= 90) {
                return "A-";
              }
              if (val >= 87) {
                return "B+";
              }
              if (val >= 83) {
                return "B";
              }
              if (val >= 80) {
                return "B-";
              }
              if (val >= 77) {
                return "C+";
              }
              if (val >= 73) {
                return "C";
              }
              if (val >= 70) {
                return "C-";
              }
              if (val >= 67) {
                return "D+";
              }
              if (val >= 63) {
                return "D";
              }
              if (val >= 60) {
                return "D-";
              }
              return "F";
            },
          },
        },
      },
    },
    fill: {
      type: "solid",
      colors: ["#c79436"],
    },
    labels: [label],
  };

  return (
    <Chart
      type="radialBar"
      options={options}
      series={series}
      height={height}
      onClick={onClick}
    />
  );
};
