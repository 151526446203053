import React from "react";
import { XIcon } from "@heroicons/react/outline";
import { PolicyObjective } from "types";
import { Container, FlexContainer, ListItem, Text } from "components/shared";

type ObjectiveListItemProps = {
  objective: PolicyObjective;
  disabled?: boolean;
  onChange?: (value: PolicyObjective) => void;
  onRemove?: (value: PolicyObjective) => void;
};

export const ObjectiveListItem = ({
  objective,
  disabled,
  onChange,
  onRemove,
}: ObjectiveListItemProps) => {
  if (!objective.objective) {
    return null;
  }
  return (
    <ListItem>
      <FlexContainer alignItems="items-center">
        <FlexContainer direction="flex-col" paddingRight="pr-4" flex="flex-1">
          <Text
            textSize="text-sm"
            textColor="text-gray-900"
            fontWeight="font-medium"
          >
            {objective.objective?.name}
          </Text>
          <Container marginTop="mt-1">
            <Text textSize="text-sm" textColor="text-gray-300">
              {objective.objective?.description}
            </Text>
          </Container>
        </FlexContainer>
        {!disabled && (
          <FlexContainer>
            <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={() => {
                if (!disabled && onRemove) {
                  onRemove(objective);
                }
              }}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </FlexContainer>
        )}
      </FlexContainer>
    </ListItem>
  );
};
