import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ArrowSmLeftIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import styled from "styled-components";
import {
  FlexContainer,
  GreenPill,
  Header,
  InitiativeList,
  KeyResultList,
  LoadingIcon,
  Pill,
  PolicyCode,
  PolicyHistoryChart,
  PolicySentence,
  PolicyTableRowDropdown,
  Text,
  Title,
} from "components/shared";
import { useDispatch, useSelector } from "store";
import { fetchPolicyByID, selectPolicyByID } from "store/reducers";

const Card = styled.div`
  padding: 1rem;
  background: white;
  border-radius: 0.5rem;
`;

export const PolicyPage = () => {
  const dispatch = useDispatch();
  const { policyID } = useParams();
  const policy = useSelector(selectPolicyByID(policyID || ""));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!policy && policyID) {
      dispatch(fetchPolicyByID(policyID));
    }
  }, [policy, policyID]);

  if (!policy || !policyID) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <FlexContainer
      direction="flex-col"
      gap="gap-10"
      flex="flex-1"
      width="w-full"
    >
      <FlexContainer direction="flex-row" justify="justify-between">
        <FlexContainer alignItems="items-center">
          <ArrowSmLeftIcon className="inline w-5" />
          <Link to="/dashboard">
            <Text>Return to dashboard</Text>
          </Link>
        </FlexContainer>
        <div>
          <PolicyTableRowDropdown policyId={policyID} />
        </div>
      </FlexContainer>

      <FlexContainer direction="flex-col" gap="gap-2">
        <FlexContainer alignItems="items-center">
          <PolicyCode code={policy.code} />
          <div className="ml-2">
            <GreenPill
              label={policy.status === "done" ? "Successful" : "On track"}
            />
          </div>
          <div className="ml-2">
            <Pill
              backgroundColor="bg-gold-700"
              label={`Since ${dayjs(policy.dateStart).format("LL")}`}
            />
          </div>
        </FlexContainer>
        <Title>
          <PolicySentence policy={policy} />
        </Title>
        <Text>
          The dashboard provides visibility and control over projects.
        </Text>
      </FlexContainer>

      <FlexContainer direction="flex-col" gap="gap-2">
        <Text>Policy CO2 impact 1.07T</Text>
      </FlexContainer>

      <PolicyHistoryChart policyId={policyID} />

      <FlexContainer direction="flex-col" gap="gap-2">
        <Header>Key results</Header>
        <Card className="text-gray-900">
          <KeyResultList
            disabled
            policy={policy}
            keyResults={policy.keyResults}
          />
        </Card>
      </FlexContainer>

      <FlexContainer direction="flex-col" gap="gap-2">
        <Header>Solutions</Header>
        <Card className="text-gray-900">
          <InitiativeList
            disabled
            policy={policy}
            initiatives={policy.initiatives}
          />
        </Card>
      </FlexContainer>
    </FlexContainer>
  );
};
