import { CDMChart } from "components/shared/charts/CDMChart/CDMChart";
import { useSelector } from "store";
import {
  selectCoreMetricsList,
  selectKeyPerformanceMetricsList,
} from "store/reducers";
import { CoreMetric } from "types";
import { LoadingIcon } from "components/shared/icons";

export const WellbeingDashboardMetric = () => {
  const coreMetrics = useSelector(selectCoreMetricsList);
  const metricsList = useSelector(selectKeyPerformanceMetricsList);
  const coreMetricScores = coreMetrics.reduce(
    (scores: number[], coreMetric: CoreMetric) => {
      const metrics = metricsList.filter(
        (metric) => metric.coreMetricId === coreMetric.id
      );
      const totalWeights = metrics.reduce((totalWeight, metric) => {
        return totalWeight + metric.weight;
      }, 0);
      const weights = metrics.reduce((totalValue, metric) => {
        return totalValue + metric.normalisedValue * (metric.weight / 100);
      }, 0);
      return [...scores, (weights * 100) / totalWeights];
    },
    []
  );
  const wellbeingScore =
    coreMetricScores.reduce((total, score) => total + score, 0) /
    coreMetricScores.length;

  if (!coreMetrics.length || !metricsList.length) {
    return <LoadingIcon />;
  }

  return <CDMChart label="Wellbeing" value={wellbeingScore} height={200} />;
};
