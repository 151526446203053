import { ComponentProps } from "react";
import classNames from "classnames";

export const ModalBody = ({
  className,
  ...otherProps
}: ComponentProps<"div">) => {
  return (
    <div
      {...otherProps}
      className={classNames("flex flex-col flex-1 justify-start", className)}
    />
  );
};
