import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import { v4 as uuid } from "uuid";
import {
  ArrowRightIcon,
  BookOpenIcon,
  CheckIcon,
  ScaleIcon,
} from "@heroicons/react/outline";
import {
  CreateObjectiveRequest,
  CreatePolicyRequest,
  Direction,
  Initiative,
  KeyResult,
  Policy,
  PolicyObjective,
  UUID,
} from "types";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "store";
import {
  createObjective,
  createPolicy,
  openPopupFlag,
  selectKeyPerformanceMetrics,
  selectObjectivesList,
} from "store/reducers";
import { useModal } from "hooks/useModal";
import {
  FlexContainer,
  PolicyDurationInput,
  KeyResultList,
  PrimaryButton,
  WhiteButton,
  InitiativeList,
  CreatePolicyModalStep,
} from "components/shared";
import { DecisionModel } from "features/decisions/types/decisionModel";
import { ObjectiveList } from "components/shared/features/objectives/ObjectiveList/ObjectiveList";

type CreatePolicyModalFormProps = {
  step: CreatePolicyModalStep;
  decision?: DecisionModel;
  onStepChange: (step: CreatePolicyModalStep) => void;
  onSuccess?: () => void;
};

export const CreatePolicyModalForm = ({
  step,
  decision,
  onStepChange,
  onSuccess,
}: CreatePolicyModalFormProps) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const keyPerformanceMetrics = useSelector(selectKeyPerformanceMetrics);
  const objectivesList = useSelector(selectObjectivesList);
  const [policy, setPolicy] = useState<Policy>({
    id: "",
    active: true,
    status: "running",
    code: 0,
    objectiveId: "",
    deltaDirection: "increase",
    deltaUnit: "percent",
    deltaAmount: 5,
    objectives: [],
    initiatives: [],
    keyResults: [],
    dateStart: dayjs().toISOString(),
    dateEnd: undefined,
    dateCreated: "",
    dateUpdated: "",
  });
  const [objectives, setObjectives] = useState<Record<UUID, PolicyObjective>>(
    {}
  );
  const [keyResults, setKeyResults] = useState<Record<UUID, KeyResult>>({});
  const [initiatives, setInitiatives] = useState<Record<UUID, Initiative>>({});

  useEffect(() => {
    const { action, ...otherParams } = parse(search);
    if (action === "create_policy") {
      const {
        solutionIds,
        objectiveId,
        direction,
        timescale,
      }: {
        solutionIds?: string;
        objectiveId?: UUID;
        direction?: Direction;
        timescale?: number;
      } = otherParams as any;
      if (objectiveId) {
        setPolicy((prevState) => ({
          ...prevState,
          objectiveId: objectiveId || prevState.objectiveId,
          deltaDirection: direction || prevState.deltaDirection,
          dateEnd: timescale
            ? dayjs().add(timescale, "month").endOf("month").toISOString()
            : prevState.dateEnd,
        }));
      }

      if (solutionIds) {
        setInitiatives((prevState) => {
          const defaultInitiatives: Initiative[] = solutionIds
            .split(",")
            .map((solutionId) => ({
              id: uuid(),
              policyId: "",
              solutionId,
              dateCreated: "",
              dateUpdated: "",
            }));
          return {
            ...prevState,
            ...defaultInitiatives.reduce(
              (values, value) => ({
                ...values,
                [value.id]: value,
              }),
              {}
            ),
          };
        });
      }
    }
    if (decision) {
      initFromDecision(decision);
    }
  }, []);

  const initFromDecision = async (decision: DecisionModel) => {
    // Create objectives or resolve existing objectives
    const resolvedObjectives = await Promise.all(
      decision.outcomes.map(async (outcome): Promise<PolicyObjective> => {
        const normalisedOutcome = outcome.toLowerCase().trim();
        const existingObjective = objectivesList.find(
          (value) => value.name.toLowerCase().trim() === normalisedOutcome
        );

        if (existingObjective) {
          return {
            id: uuid(),
            policyId: "",
            objectiveId: existingObjective.id,
            objective: existingObjective,
            dateCreated: "",
            dateUpdated: "",
          };
        }

        const requestBody: CreateObjectiveRequest = {
          name: outcome,
          description: "",
        };
        const response = await dispatch(createObjective(requestBody)).unwrap();
        return {
          id: uuid(),
          policyId: "",
          objectiveId: response.id,
          objective: response,
          dateCreated: "",
          dateUpdated: "",
        };
      })
    );
    setObjectives((prevState) => {
      return {
        ...resolvedObjectives.reduce(
          (values, value) => ({
            ...values,
            [value.id]: value,
          }),
          {}
        ),
      };
    });

    setKeyResults((prevState) => {
      const results = decision.metrics
        .reduce<KeyResult[]>((items, item) => {
          if (!item.metric) return [...items];
          return [
            ...items,
            {
              id: uuid(),
              policyId: "",
              metricId: item.metric.id,
              deltaDirection: "increase",
              deltaUnit: "percent",
              deltaAmount: 0,
              initialMetricValue:
                keyPerformanceMetrics[item.metric.id]?.value || 0,
              dateCreated: "",
              dateUpdated: "",
            },
          ];
        }, [])
        // Remove duplicate initiatives by metricId
        .filter(
          (value, index, self) =>
            self.findIndex((t) => t.metricId === value.metricId) === index
        );

      return {
        ...results.reduce(
          (values, value) => ({
            ...values,
            [value.id]: value,
          }),
          {}
        ),
      };
    });

    setInitiatives((prevState) => {
      const results = decision.actions
        .reduce<Initiative[]>((results, action): Initiative[] => {
          return [
            ...results,
            ...action.solutions.map((solution): Initiative => {
              return {
                id: uuid(),
                policyId: "",
                solutionId: solution.id,
                dateCreated: "",
                dateUpdated: "",
              };
            }),
          ];
        }, [])
        // Remove duplicate initiatives by solutionId
        .filter(
          (value, index, self) =>
            self.findIndex((t) => t.solutionId === value.solutionId) === index
        );

      return {
        ...results.reduce(
          (values, value) => ({
            ...values,
            [value.id]: value,
          }),
          {}
        ),
      };
    });
  };

  const handleObjectiveChange = (value: Policy) => {
    setPolicy({
      ...policy,
      ...value,
    });
  };

  const handleKeyResultCreate = (value: KeyResult) => {
    setKeyResults({ ...keyResults, [value.id]: value });
  };

  const handleKeyResultChange = (value: KeyResult) => {
    setKeyResults({
      ...keyResults,
      [value.id]: value,
    });
  };

  const handleKeyResultRemove = (value: KeyResult) => {
    const newValue = { ...keyResults };
    delete newValue[value.id];
    setKeyResults(newValue);
  };

  const handleInitiativeCreate = (value: Initiative) => {
    setInitiatives({ ...initiatives, [value.id]: value });
  };

  const handleInitiativeRemove = (value: Initiative) => {
    const newValue = { ...initiatives };
    delete newValue[value.id];
    setInitiatives(newValue);
  };

  const handleSubmit = () => {
    const requestBody: CreatePolicyRequest = {
      ...policy,
      objectives: Object.values(objectives).map((value) => value),
      initiatives: Object.values(initiatives).map((initiative) => ({
        solutionId: initiative.solutionId,
      })),
      keyResults: Object.values(keyResults).map((keyResult) => ({
        metricId: keyResult.metricId,
        deltaDirection: keyResult.deltaDirection,
        deltaUnit: keyResult.deltaUnit,
        deltaAmount: keyResult.deltaAmount,
        dateDeadline: keyResult.dateDeadline,
      })),
    };

    dispatch(createPolicy(requestBody))
      .unwrap()
      .then(() => {
        window.scrollTo(0, 0);
        if (onSuccess) {
          onSuccess();
        }
        dispatch(
          openPopupFlag({
            appearance: "success",
            title: "Project started successfully",
          })
        );
        closeModal();
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          openPopupFlag({
            appearance: "error",
            title: "Failed to begin project",
          })
        );
      });
  };

  const renderContent = () => {
    switch (step) {
      case CreatePolicyModalStep.Objective:
        return (
          <div className="flex flex-col gap-4 color-gray-800">
            <ObjectiveList
              policy={policy}
              objectives={Object.values(objectives)}
              onCreate={(value) => {
                setObjectives({ ...objectives, [value.id]: value });
              }}
              onChange={(value) => {
                setObjectives({ ...objectives, [value.id]: value });
              }}
              onRemove={(value) => {
                const newValue = { ...objectives };
                delete newValue[value.id];
                setObjectives(newValue);
              }}
            />

            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <h3 className="text-md leading-6 font-medium text-gray-900">
                  Your timeframe
                </h3>
                <p className="text-sm text-gray-500">
                  Select the duration of your project
                </p>
              </div>
              <PolicyDurationInput
                value={policy}
                onChange={handleObjectiveChange}
              />
            </div>
          </div>
        );
      case CreatePolicyModalStep.KeyResults:
        return (
          <FlexContainer direction="flex-col" width="w-full">
            <KeyResultList
              policy={policy}
              keyResults={Object.values(keyResults)}
              onCreate={handleKeyResultCreate}
              onChange={handleKeyResultChange}
              onRemove={handleKeyResultRemove}
            />
          </FlexContainer>
        );
      case CreatePolicyModalStep.Interventions:
        return (
          <FlexContainer direction="flex-col" width="w-full">
            <InitiativeList
              policy={policy}
              metricIds={Object.values(keyResults).map(
                (value) => value.metricId
              )}
              initiatives={Object.values(initiatives)}
              onCreate={handleInitiativeCreate}
              onRemove={handleInitiativeRemove}
            />
          </FlexContainer>
        );
    }
  };
  const renderActions = () => {
    switch (step) {
      case CreatePolicyModalStep.Objective:
        return (
          <>
            <div className="mr-2">
              <WhiteButton onClick={closeModal}>Cancel</WhiteButton>
            </div>
            <PrimaryButton
              trailingIcon={ArrowRightIcon}
              onClick={() => onStepChange(CreatePolicyModalStep.KeyResults)}
              disabled={Object.values(objectives).length === 0}
            >
              Configure key results
            </PrimaryButton>
          </>
        );
      case CreatePolicyModalStep.KeyResults:
        return (
          <>
            <div className="mr-2">
              <WhiteButton
                onClick={() => onStepChange(CreatePolicyModalStep.Objective)}
              >
                Back
              </WhiteButton>
            </div>
            <PrimaryButton
              trailingIcon={ArrowRightIcon}
              onClick={() => onStepChange(CreatePolicyModalStep.Interventions)}
            >
              Choose solutions
            </PrimaryButton>
          </>
        );
      case CreatePolicyModalStep.Interventions:
        return (
          <>
            <div className="mr-2">
              <WhiteButton
                onClick={() => onStepChange(CreatePolicyModalStep.KeyResults)}
              >
                Back
              </WhiteButton>
            </div>
            <PrimaryButton leadingIcon={CheckIcon} onClick={handleSubmit}>
              Begin project
            </PrimaryButton>
          </>
        );
    }
  };

  return (
    <>
      <div className="flex flex-1 flex-col grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 overflow-auto px-2">
        {renderContent()}
      </div>
      <FlexContainer
        justify={
          [
            CreatePolicyModalStep.Objective,
            CreatePolicyModalStep.KeyResults,
          ].indexOf(step) !== -1
            ? "justify-between"
            : "justify-end"
        }
      >
        {step === CreatePolicyModalStep.Objective && (
          <FlexContainer marginTop="mt-5" padding="p-1">
            <div>
              <WhiteButton leadingIcon={BookOpenIcon}>Templates</WhiteButton>
            </div>
          </FlexContainer>
        )}
        {step === CreatePolicyModalStep.KeyResults && (
          <FlexContainer marginTop="mt-5" padding="p-1">
            <div>
              <WhiteButton leadingIcon={ScaleIcon}>Policy adviser</WhiteButton>
            </div>
          </FlexContainer>
        )}
        <FlexContainer marginTop="mt-5" padding="p-1">
          {renderActions()}
        </FlexContainer>
      </FlexContainer>
    </>
  );
};
