import { Objective, Policy, PolicyObjective } from "types";
import { v4 as uuid } from "uuid";
import {
  FlexContainer,
  ObjectiveListItem,
  ObjectiveSelector,
} from "components/shared";
import { PolicySentence } from "components/shared/features/policies/PolicySentence/PolicySentence";

type ObjectiveListProps = {
  policy: Policy;
  objectives: PolicyObjective[];
  disabled?: boolean;
  onCreate?: (value: PolicyObjective) => void;
  onChange?: (value: PolicyObjective) => void;
  onRemove?: (value: PolicyObjective) => void;
};

export const ObjectiveList = ({
  policy,
  objectives,
  disabled,
  onCreate,
  onChange,
  onRemove,
}: ObjectiveListProps) => {
  const handleAddObjective = (value: Objective) => {
    const newValue: PolicyObjective = {
      id: uuid(),
      policyId: "",
      objectiveId: value.id,
      objective: value,
      dateCreated: "",
      dateUpdated: "",
    };
    onCreate?.(newValue);
  };

  return (
    <div className="py-5 px-1">
      <div className="bg-white mb-2 rounded-md">
        {!disabled && (
          <div className="mb-5">
            <h3 className="text-md leading-6 font-medium text-gray-900">
              Your objectives
            </h3>
            <p className="text-sm text-gray-500">
              Select the objectives for your project
            </p>
          </div>
        )}
        <ul role="list">
          {objectives.map((objective) => {
            return (
              <div key={objective.id}>
                <ObjectiveListItem
                  disabled={disabled}
                  objective={objective}
                  onChange={onChange}
                  onRemove={onRemove}
                />
              </div>
            );
          })}
          {!disabled && objectives.length === 0 && (
            <li className="block">
              <FlexContainer justify="justify-start" alignItems="items-center">
                <ObjectiveSelector
                  label="Select an objective"
                  filterIds={objectives.map(
                    ({ objective }) => objective?.id || ""
                  )}
                  onSelect={handleAddObjective}
                />
              </FlexContainer>
            </li>
          )}
        </ul>
      </div>
      {!disabled && objectives.length > 0 && (
        <FlexContainer marginTop="mt-5">
          <ObjectiveSelector
            label="Select another objective"
            filterIds={objectives.map(({ objective }) => objective?.id || "")}
            onSelect={handleAddObjective}
          />
        </FlexContainer>
      )}
    </div>
  );
};
