import { Outlet } from "react-router-dom";
import { AutoDismissFlags } from "components/popups";

export const PolicyLayout = () => {
  return (
    <>
      <AutoDismissFlags key="layoutFlags" />
      <main className="flex flex-1">
        <div className="w-full max-w-xl lg:max-w-7xl mx-auto px-8 my-8 text-white">
          <Outlet />
        </div>
      </main>
    </>
  );
};
