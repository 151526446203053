import classNames from "classnames";
import { Combobox } from "@headlessui/react";
import { Objective } from "types";

type ObjectiveSelectorItemProps = {
  objective: Objective;
};

export const ObjectiveSelectorItem = ({
  objective,
}: ObjectiveSelectorItemProps) => {
  return (
    <Combobox.Option
      key={objective.id}
      value={objective}
      className={({ active }) =>
        classNames(
          "cursor-pointer select-none px-4 py-2",
          active && "bg-zinc-600  text-white"
        )
      }
    >
      {objective.name}
    </Combobox.Option>
  );
};
