import { Text } from "components/shared/typography";
import { TailwindTextColor } from "types/tailwind";

type PolicyCodeProps = {
  key?: string;
  code: number;
  textColor?: TailwindTextColor;
};

export const PolicyCode = ({
  key = "PROJECT",
  code,
  textColor = "text-current",
}: PolicyCodeProps) => {
  return (
    <Text textColor={textColor} textSize="text-xs">
      {key}-{code}
    </Text>
  );
};
