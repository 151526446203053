import classNames from "classnames";
import {
  TailwindBorderColor,
  TailwindHeight,
  TailwindLineHeight,
  TailwindPadding,
  TailwindPlaceholderColor,
  TailwindRounding,
  TailwindTextColor,
  TailwindTextSize,
  TailwindWidth,
} from "types/tailwind";

export type GenericInputProps = {
  name: string;
  value?: any;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
  onChange?: (event: any) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  onClick?: (event: any) => void;
  rounding?: TailwindRounding;
  width?: TailwindWidth;
  height?: TailwindHeight;
  padding?: TailwindPadding;
  textColor?: TailwindTextColor;
  borderColor?: TailwindBorderColor;
  placeholderColor?: TailwindPlaceholderColor;
  textSize?: TailwindTextSize;
  lineHeight?: TailwindLineHeight;
};

export type InputProps = GenericInputProps & {
  type: string;
};

export const Input = ({
  width = "w-full",
  rounding = "rounded-md",
  textColor = "text-gray-900",
  borderColor = "border-gray-300",
  placeholderColor = "placeholder-gray-500",
  textSize = "text-base",
  height = "",
  padding = "px-3",
  lineHeight,
  ...otherProps
}: InputProps) => {
  const classes = classNames(
    "appearance-none relative block border focus:outline-none focus:ring-gold-600 focus:border-gold-600",
    width,
    height,
    padding,
    rounding,
    textColor,
    borderColor,
    placeholderColor,
    lineHeight
  );
  return <input className={classes} {...otherProps} />;
};
