import { ReactNode } from "react";
import classNames from "classnames";
import {
  TailwindFontWeight,
  TailwindLineHeight,
  TailwindTextColor,
  TailwindTextSize,
} from "types/tailwind";

type TextProps = {
  textColor?: TailwindTextColor;
  fontWeight?: TailwindFontWeight;
  textSize?: TailwindTextSize;
  lineHeight?: TailwindLineHeight;
  children?: ReactNode;
};

export const Text = ({
  textColor = "text-current",
  textSize = "text-sm",
  fontWeight = "",
  lineHeight = "",
  children,
}: TextProps) => {
  const classes = classNames(textColor, textSize, fontWeight, lineHeight);
  return <p className={classes}>{children}</p>;
};
