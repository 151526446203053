import { XIcon } from "@heroicons/react/outline";

type ModalHeaderProps = {
  title: string;
  onClose: () => void;
};

export const ModalHeader = ({ title, onClose }: ModalHeaderProps) => {
  return (
    <div className="flex flex-row justify-between item-center">
      <div>
        <h1 className="text-xl font-extrabold text-blue-gray-900">{title}</h1>
      </div>
      <div className="h-7">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
          onClick={onClose}
        >
          <span className="sr-only">Close panel</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};
