import { Objective } from "types";
import { useDispatch } from "store";
import { createObjective } from "store/reducers";

type ObjectiveSelectorEmptyProps = {
  query?: string;
  onCreate?: (value: Objective) => void;
};

export const ObjectiveSelectorEmpty = ({
  query,
  onCreate,
}: ObjectiveSelectorEmptyProps) => {
  const dispatch = useDispatch();

  const handleCreate = () => {
    if (query) {
      dispatch(
        createObjective({
          name: query,
          description: "",
        })
      )
        .unwrap()
        .then((data) => onCreate && onCreate(data));
    }
  };
  return (
    <div className="p-4 text-sm text-center">
      <p className="mb-5 text-gray-500">No objective found</p>
      {query && (
        <p
          className="block rounded-md text-blue-400 cursor-pointer hover:bg-blue-50 px-2 py-1"
          onClick={handleCreate}
        >
          Add the objective "{query}"
        </p>
      )}
    </div>
  );
};
