import { Fragment, ReactNode, useState } from "react";
import { Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { Popover } from "components/shared/popovers/Popover/Popover";
import { UUID } from "types";
import { PolicyTable } from "components/shared/features/policies";
import { Heading } from "components/shared/typography";

type CoreDashboardMetricPoliciesPopoverProps = {
  coreMetricId: UUID;
  children: ReactNode;
};

const PopoverContent = ({
  coreMetricId,
}: Pick<CoreDashboardMetricPoliciesPopoverProps, "coreMetricId">) => {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div className="bg-white text-gray-700 p-5 rounded-lg h-60 w-[31rem] overflow-auto">
        <Heading>Impacted by projects</Heading>
        <PolicyTable
          coreMetricIds={[coreMetricId]}
          maxRows={3}
          appearance="compact"
        />
      </div>
    </Transition>
  );
};

export const CoreDashboardMetricPoliciesPopover = ({
  coreMetricId,
  children,
}: CoreDashboardMetricPoliciesPopoverProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            as="div"
            ref={setReferenceElement}
            className="cursor-pointer"
          >
            {children}
          </Popover.Button>
          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="z-10 px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0"
          >
            {open && <PopoverContent coreMetricId={coreMetricId} />}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};
