import { useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { useModal } from "hooks/useModal";
import {
  CreatePolicyModalForm,
  CreatePolicyModalStep,
  CreatePolicyModalSteps,
} from "components/shared";
import { DecisionModel } from "features/decisions/types/decisionModel";

type CreatePolicyModalProps = {
  decision?: DecisionModel;
  onSuccess?: () => void;
};

export const CreatePolicyModal = ({
  decision,
  onSuccess,
}: CreatePolicyModalProps) => {
  const { closeModal } = useModal();
  const [step, setStep] = useState<CreatePolicyModalStep>(
    CreatePolicyModalStep.Objective
  );

  return (
    <div className="p-4 h-screen w-screen flex flex-col items-center justify-center">
      <div className="inline-block flex flex-col align-bottom bg-white h-3/4 w-2/3 xl:w-1/2 rounded-lg p-5 pb-4 text-left shadow-xl transform transition-all">
        <div className="flex flex-row justify-between item-center gap-10 mb-5">
          <div className="flex-1">
            <CreatePolicyModalSteps step={step} onStepChange={setStep} />
          </div>
          <div className="mr-3 h-7">
            <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={closeModal}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="flex flex-col flex-1 overflow-y-hidden">
          <CreatePolicyModalForm
            step={step}
            decision={decision}
            onStepChange={setStep}
            onSuccess={onSuccess}
          />
        </div>
      </div>
    </div>
  );
};
