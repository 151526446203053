import React, { ChangeEvent, useCallback, useState } from "react";
import { CodeIcon } from "@heroicons/react/outline";
import { Metric } from "types";
import { useDispatch } from "store";
import { useEffectUpdate } from "hooks";
import { openPopupFlag, updateMetric } from "store/reducers";
import { DataGrid, DataItem } from "components/shared/layouts";
import { InputGroup, NumberInput, TextInput } from "components/shared/inputs";
import { Header, Text } from "components/shared/typography";
import { MetricIngestHistory } from "components/shared/features/metrics/MetricIngestHistory/MetricIngestHistory";
import { CoreMetricSelector } from "components/shared/features/coreMetrics";
import { SecondaryButton } from "components/shared/buttons";

type KeyPerformanceMetricFormProps = {
  entity: Metric;
};

export const MetricForm = ({ entity }: KeyPerformanceMetricFormProps) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(entity);
  const [dirty, setDirty] = useState(false);

  const cb = useCallback(() => {
    if (values.coreMetricId !== entity.coreMetricId) {
      saveChanges();
    }
  }, [values.coreMetricId]);
  useEffectUpdate(cb);

  const handleBlur = () => {
    if (dirty) {
      saveChanges();
    }
  };

  const saveChanges = () => {
    dispatch(updateMetric(values))
      .unwrap()
      .then((data) => {
        setValues(data);
        dispatch(
          openPopupFlag({
            appearance: "success",
            title: "Key result updated",
          })
        );
      });
  };

  const handleInputChange = (name: string) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      handleChange(name, event.target.value);
    };
  };

  const handleChange = (name: string, value: any) => {
    setDirty(true);
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div>
      <InputGroup label="Name">
        <TextInput
          name="name"
          value={values.name}
          onChange={handleInputChange("name")}
          onBlur={handleBlur}
        />
      </InputGroup>
      <InputGroup label="Unit of Measurement">
        <TextInput
          name="measurementUnit"
          placeholder="Enter unit of measurement e.g. kg"
          value={values.measurementUnit}
          onChange={handleInputChange("measurementUnit")}
          onBlur={handleBlur}
        />
      </InputGroup>
      <InputGroup label="Description">
        <TextInput
          name="description"
          value={values.description}
          onChange={handleInputChange("description")}
          onBlur={handleBlur}
        />
      </InputGroup>

      <div className="mt-10 mb-5">
        <Header>Key metric</Header>
      </div>
      <InputGroup label="Key metric">
        <CoreMetricSelector
          label="Select key metric"
          onSelect={(value) => handleChange("coreMetricId", value?.id)}
          value={entity.coreMetricId}
          filterCoreMetrics={
            entity.coreMetricId ? [entity.coreMetricId] : undefined
          }
        />
      </InputGroup>
      {entity.coreMetricId && (
        <InputGroup label="Weight">
          <div className="mb-2">
            <Text textColor="text-gray-400">
              Enter a metric weight between 0 and 10.
            </Text>
          </div>
          <NumberInput
            name="weight"
            value={values.weight}
            min={0}
            max={10}
            placeholder="Enter the metric weight"
            onChange={(value) => handleChange("weight", value)}
            onBlur={handleBlur}
          />
        </InputGroup>
      )}
      <InputGroup label="Plugin">
        <SecondaryButton leadingIcon={CodeIcon} width="w-auto">
          Connect plugin
        </SecondaryButton>
      </InputGroup>

      <div className="mt-10 mb-5">
        <Header>Thresholds</Header>
      </div>
      <InputGroup label="Threshold - Unacceptable">
        <NumberInput
          name="thresholdUnacceptable"
          value={values.thresholdUnacceptable}
          placeholder="Enter the unacceptable threshold"
          onChange={(value) => handleChange("thresholdUnacceptable", value)}
          onBlur={handleBlur}
        />
      </InputGroup>
      <InputGroup label="Threshold - Ideal">
        <NumberInput
          name="thresholdIdeal"
          value={values.thresholdIdeal}
          placeholder="Enter the ideal threshold"
          onChange={(value) => handleChange("thresholdIdeal", value)}
          onBlur={handleBlur}
        />
      </InputGroup>

      <div className="mt-10 mb-5">
        <Header>API for data ingestion</Header>
        <p className="mt-1 text-blue-gray-500">
          Programmatically update the key result value using an API integration
        </p>
      </div>

      <InputGroup label="API key">
        <code>
          sk_1ca82a593e7e4668b5450ab46755ec17
        </code>
      </InputGroup>

      <InputGroup label="Read result value">
        <code>
          GET{" "}
          {`https://ingest.fpi.earth/results/${entity.id}`}
        </code>
      </InputGroup>

      <InputGroup label="Set result value">
        <code>
          PUT{" "}
          {`https://ingest.fpi.earth/results/${entity.id}`}
        </code>
      </InputGroup>

      <InputGroup label="Ingest history">
        <MetricIngestHistory metricId={entity.id} />
      </InputGroup>
    </div>
  );
};
