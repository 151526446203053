import { ComponentProps } from "react";
import classNames from "classnames";

export const ModalFooter = ({
  className,
  ...otherProps
}: ComponentProps<"div">) => {
  return (
    <div
      {...otherProps}
      className={classNames(
        "bg-gray-50 rounded-b-lg -mx-6 -my-6 px-6 py-3 gap-3 sm:flex sm:flex-row-reverse sm:px-6",
        className
      )}
    />
  );
};
