// @ts-nocheck
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ComponentAppearance } from "types";

type LineChartProps = {
  decreasing?: boolean;
  appearance?: ComponentAppearance;
};

export const LineChart = ({
  decreasing = false,
  appearance = "full",
}: LineChartProps) => {
  const direction = decreasing ? -1 : 1;
  const [modifier, _] = useState((Math.random() <= 0.5 ? 1 : 1) * direction);
  const data = [
    0,
    modifier * 1.5,
    modifier * 1.8,
    modifier * 2.25,
    modifier * 4.6,
    modifier * 4.1,
    modifier * 6.7,
    modifier * 7.3,
    modifier * 8.4,
    modifier * 8.9,
    modifier * 8.5,
    modifier * 8.9,
  ];
  const height = 80;
  const state = {
    series: [
      {
        data: data,
      },
    ],
    options: {
      colors: ["#c79436"],
      chart: {
        id: "realtime",
        type: "line",
        height: "auto",
        animations: {
          enabled: appearance !== "compact",
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
        toolbar: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: 5,
        dashArray: 2,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      title: {
        show: false,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 10,
      },
      yaxis: {
        show: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      grid: {
        show: false,
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="line"
        height={height}
      />
    </div>
  );
};
