import {
  CoreMetricsTable,
  CreateCoreMetricModal,
  FlexContainer,
  PrimaryButton,
} from "components/shared";
import { useModal } from "hooks/useModal";

export const CoreMetricsSettingsPage = () => {
  const { openModal } = useModal();

  const createCoreMetric = () => openModal(<CreateCoreMetricModal />);

  return (
    <div className="max-w-3xl mx-auto py-10 px-4">
      <FlexContainer justify="justify-between">
        <h1 className="text-3xl font-extrabold text-blue-gray-900 mb-10">
          Key metrics
        </h1>
        <div>
          <PrimaryButton onClick={createCoreMetric}>Create</PrimaryButton>
        </div>
      </FlexContainer>
      <div className="shadow border-b border-gray-200 sm:rounded-lg overflow-hidden">
        <CoreMetricsTable />
      </div>
    </div>
  );
};
