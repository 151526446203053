import { selectCoreMetricsList } from "store/reducers";
import { useSelector } from "store";
import {CoreDashboardMetric, LoadingIcon} from "components/shared";

export const CoreDashboardMetrics = () => {
  const coreMetrics = useSelector(selectCoreMetricsList);
  if (coreMetrics.length === 0) {
    return null;
  }

  return (
    <div
      className={`grid bg-ocean-900/90 lg:grid-cols-5 xl:grid-cols-${coreMetrics.length} rounded-md gap-2`}
    >
      {coreMetrics.map((metric) => (
        <CoreDashboardMetric key={metric.id} coreMetricId={metric.id} />
      ))}
    </div>
  );
};
