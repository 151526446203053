import { RoutePath } from "routes/RoutePath";
import { BookOpenIcon, HomeIcon } from "@heroicons/react/outline";

export const applicationMenu = [
  {
    name: "Dashboard",
    to: RoutePath.dashboard.root,
    icon: HomeIcon,
    current: true,
  },
  {
    name: "Policies",
    to: RoutePath.policies.root,
    icon: BookOpenIcon,
    current: false,
  },
];
