export const RoutePath = {
  root: "/",
  dashboard: {
    root: "/dashboard",
    coreMetrics: "core-metrics",
    coreMetric: "/dashboard/core-metrics/:id",
    kpis: "kpis",
    kpi: "/dashboard/kpis/:id",
    objectives: "objectives",
    objective: "/dashboard/objectives/:id",
    keyResults: "key-results",
    interventions: "interventions",
    intervention: "/dashboard/interventions/:id",
    wellbeing: "wellbeing",
  },
  login: "/login",
  logout: "/logout",
  policies: {
    root: "/policies",
    policy: "/policies/:policyID",
  },
  solutions: {
    root: "/solutions",
    solution: "/solutions/:id",
  },
  settings: {
    root: "/settings",
    account: "account",
    coreMetrics: "core-metrics",
    coreMetric: "core-metrics/:id",
    kpis: "kpis",
    kpi: "kpis/:id",
    objectives: "objectives",
    keyResults: "key-results",
    interventions: "interventions",
    wellbeing: "wellbeing",
  },
};
