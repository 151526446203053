import { useState } from "react";
import { usePopper } from "react-popper";
import { Combobox, Portal } from "@headlessui/react";
import { PencilIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "store";
import { CoreMetric, UUID } from "types";
import {
  createCoreMetric,
  selectCoreMetricByID,
  selectCoreMetricsList,
} from "store/reducers";
import {
  Popover,
  CoreMetricSelectorEmpty,
  CoreMetricSelectorButton,
  CoreMetricSelectorItem,
} from "components/shared/index";

type CoreMetricSelectorProps = {
  label?: string;
  value?: UUID;
  filterCoreMetrics?: UUID[];
  onSelect: (value: CoreMetric) => void;
};

export const CoreMetricSelector = ({
  label,
  value,
  filterCoreMetrics = [],
  onSelect,
}: CoreMetricSelectorProps) => {
  const dispatch = useDispatch();
  const entitiesList = useSelector(selectCoreMetricsList);
  const entity = useSelector(selectCoreMetricByID(value));
  const availableOptions = entitiesList.filter(({ id }) =>
    filterCoreMetrics ? filterCoreMetrics.indexOf(id) === -1 : true
  );

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto-start",
    strategy: "fixed",
  });
  const [query, setQuery] = useState("");
  const filteredCoreMetrics =
    query === ""
      ? []
      : availableOptions.filter((value) => {
          return value.name.toLowerCase().includes(query.toLowerCase());
        });

  const handleCreateCoreMetric = (close: () => void) => {
    if (query) {
      const existing = availableOptions.find(
        (option) =>
          option.name.toLowerCase().trim() === query.toLowerCase().trim()
      );
      if (existing) {
        onSelect(existing);
        close();
        return;
      }
      dispatch(
        createCoreMetric({
          name: query.trim(),
          description: "",
          color: "",
        })
      )
        .unwrap()
        .then((data) => {
          onSelect(data);
          close();
        });
    }
  };

  return (
    <Popover className="relative py-1 pr-1">
      {({ open, close }) => (
        <>
          <Popover.Button
            as="div"
            ref={setReferenceElement}
            className="inline-block"
          >
            <CoreMetricSelectorButton
              placeholder={label}
              label={entity && entity.name}
              open={open}
            />
          </Popover.Button>
          <Portal>
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-30 px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0"
            >
              <Combobox
                as="div"
                className="divide-gray-100 w-72 rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
                onChange={(value: CoreMetric) => {
                  onSelect(value);
                  close();
                }}
                value={undefined}
              >
                <form
                  className="relative pb-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleCreateCoreMetric(close);
                  }}
                >
                  <PencilIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                  <input
                    type="text"
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-sm text-gray-800 placeholder-gray-400 focus:ring-0"
                    placeholder="Create a key metric"
                    autoFocus
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </form>
                <div className="relative border-t border-gray-200 text-sm text-zinc-300 px-4 py-2 pb-0">
                  <div className="bg-white absolute bottom-0 px-1 left-1/2 -translate-x-1/2 whitespace-nowrap">
                    Or choose from existing
                  </div>
                </div>
                {availableOptions.length === 0 ||
                (query !== "" && filteredCoreMetrics.length === 0) ? (
                  <CoreMetricSelectorEmpty />
                ) : (
                  <Combobox.Options
                    static
                    className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                  >
                    {(query === ""
                      ? availableOptions.slice(0, 5)
                      : filteredCoreMetrics
                    ).map((value) => (
                      <CoreMetricSelectorItem
                        key={value.id}
                        coreMetric={value}
                      />
                    ))}
                  </Combobox.Options>
                )}
              </Combobox>
            </Popover.Panel>
          </Portal>
        </>
      )}
    </Popover>
  );
};
