import { DecisionModel } from "features/decisions/types/decisionModel";
import { baseApi } from "store/base.api";
import { UUID } from "types";

export type GetDecisionResponse = {
  data: DecisionModel;
};

export const decisionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDecision: builder.query<GetDecisionResponse["data"], UUID>({
      query: (id: UUID) => ({
        url: `decisions/${id}`,
      }),
      transformResponse: (response: GetDecisionResponse) => response.data,
    }),
  }),
});

export const { useGetDecisionQuery, useLazyGetDecisionQuery } = decisionsApi;
