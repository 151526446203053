import { useState } from "react";
import { usePopper } from "react-popper";
import { Combobox, Portal } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/outline";
import { useSelector } from "store";
import { Metric, Objective, UUID } from "types";
import { selectObjectivesList } from "store/reducers";
import {
  Popover,
  ObjectiveSelectorItem,
  KeyResultSelectorButton,
  KeyResultSelectorEmpty,
  ObjectiveSelectorEmpty,
} from "components/shared";

type ObjectiveSelectorProps = {
  label?: string;
  filterIds?: UUID[];
  onSelect: (value: Objective) => void;
};

export const ObjectiveSelector = ({
  label,
  filterIds = [],
  onSelect,
}: ObjectiveSelectorProps) => {
  const list = useSelector(selectObjectivesList);
  const availableOptions = list.filter(({ id }) =>
    filterIds ? filterIds.indexOf(id) === -1 : true
  );

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const [query, setQuery] = useState("");
  const filteredList =
    query === ""
      ? []
      : availableOptions.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button as="div" ref={setReferenceElement}>
            <KeyResultSelectorButton label={label} open={open} />
          </Popover.Button>
          <Portal>
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-30 px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0"
            >
              <Combobox
                as="div"
                className="divide-gray-100 w-64 rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
                onChange={(value: Metric) => {
                  onSelect(value);
                  close();
                }}
                value={undefined}
              >
                <div className="relative">
                  <SearchIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-sm text-gray-800 placeholder-gray-400 focus:ring-0"
                    placeholder="Search..."
                    autoFocus
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>
                {availableOptions.length === 0 ||
                (query !== "" && filteredList.length === 0) ? (
                  <ObjectiveSelectorEmpty query={query} />
                ) : (
                  <Combobox.Options
                    static
                    className="max-h-72 scroll-py-2 overflow-y-auto pb-2 text-sm text-gray-800"
                  >
                    {(query === ""
                      ? availableOptions.slice(0, 10)
                      : filteredList
                    ).map((option) => (
                      <ObjectiveSelectorItem
                        key={option.id}
                        objective={option}
                      />
                    ))}
                  </Combobox.Options>
                )}
              </Combobox>
            </Popover.Panel>
          </Portal>
        </>
      )}
    </Popover>
  );
};
