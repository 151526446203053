import { ReactNode } from "react";

type InputGroupProps = {
  label?: string;
  children?: ReactNode;
};

export const InputGroup = ({ label, children }: InputGroupProps) => {
  return (
    <div className="mb-5">
      {label && (
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="mt-1">{children}</div>
    </div>
  );
};
