import dayjs from "dayjs";
import { Policy } from "types";
import styled from "styled-components";

const Sentence = styled.b`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

type PolicySentenceProps = {
  policy: Policy;
};

export const PolicySentence = ({ policy }: PolicySentenceProps) => {
  const objectives = policy.objectives.reduce<string[]>((names, po) => {
    if (!po.objective) {
      return names;
    }
    return [...names, po.objective.name];
  }, []);

  const getNumberOfMonths = (): number => {
    const endDate = dayjs(policy.dateEnd);
    return endDate.diff(dayjs(), "month");
  };

  if (!objectives.length) {
    return null;
  }

  return (
    <span>
      <Sentence>{objectives.join(" and ")}</Sentence> over the next{" "}
      <b>{getNumberOfMonths()} months</b>
    </span>
  );
};
