import { UUID } from "types";
import { Fragment, ReactNode, useState } from "react";
import { Transition, Portal } from "@headlessui/react";
import { usePopper } from "react-popper";
import { useSelector } from "store";
import { selectPolicyByID } from "store/reducers";
import { Popover, Heading, List, SolutionListItem } from "components/shared";

type PolicyTableSolutionPopoverProps = {
  policyId: UUID;
  children: ReactNode;
};

const PopoverContent = ({
  policyId,
}: Pick<PolicyTableSolutionPopoverProps, "policyId">) => {
  const entity = useSelector(selectPolicyByID(policyId));

  if (!entity) {
    return null;
  }

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div className="bg-white text-gray-700 p-5 rounded-lg h-60 w-96 overflow-auto shadow-lg">
        <Heading>Solutions</Heading>
        <List>
          {entity.initiatives?.map((initiative) => (
            <SolutionListItem
              key={initiative.id}
              solutionId={initiative.solutionId}
            />
          ))}
        </List>
      </div>
    </Transition>
  );
};

export const PolicyTableSolutionPopover = ({
  policyId,
  children,
}: PolicyTableSolutionPopoverProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            as="div"
            ref={setReferenceElement}
            className="cursor-pointer"
          >
            {children}
          </Popover.Button>
          <Portal>
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-30 px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0"
            >
              {open && <PopoverContent policyId={policyId} />}
            </Popover.Panel>
          </Portal>
        </>
      )}
    </Popover>
  );
};
