import { ComponentProps } from "react";
import classNames from "classnames";
import { ModalHeader } from "components/shared/modals/ModalHeader";
import { ModalBody } from "components/shared/modals/ModalBody";
import { ModalFooter } from "components/shared/modals/ModalFooter";

const ModalContainer = ({
  className,
  ...otherProps
}: ComponentProps<"div">) => {
  return (
    <div
      {...otherProps}
      className={classNames(
        "flex flex-col gap-6 bg-white rounded-lg py-6 px-6 shadow-xl transform min-w-[30rem]",
        className
      )}
    />
  );
};

export const Modal = Object.assign(ModalContainer, {
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
});
