import { Metric, KeyResult, Policy } from "types";
import { v4 as uuid } from "uuid";
import {
  FlexContainer,
  KeyResultListItem,
  KeyResultSelector,
} from "components/shared";
import { PolicySentence } from "components/shared/features/policies/PolicySentence/PolicySentence";

type KeyResultListProps = {
  policy: Policy;
  keyResults: KeyResult[];
  disabled?: boolean;
  onCreate?: (value: KeyResult) => void;
  onChange?: (value: KeyResult) => void;
  onRemove?: (value: KeyResult) => void;
};

export const KeyResultList = ({
  policy,
  keyResults,
  disabled,
  onCreate,
  onChange,
  onRemove,
}: KeyResultListProps) => {
  const handleAddKeyResult = (value: Metric) => {
    const newValue: KeyResult = {
      id: uuid(),
      policyId: "",
      metricId: value.id,
      deltaDirection: "increase",
      deltaUnit: "percent",
      deltaAmount: 0,
      initialMetricValue: value.value,
      dateCreated: "",
      dateUpdated: "",
    };
    onCreate && onCreate(newValue);
  };

  return (
    <div className="py-5 px-1">
      <div className="bg-white mb-2 rounded-md">
        {!disabled && (
          <div className="mb-5">
            <h3 className="text-md leading-6 font-medium text-gray-900">
              Track key results
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Choose which key results to track and set the target values
            </p>
          </div>
        )}
        <ul role="list">
          {keyResults.map((keyResult) => {
            return (
              <div key={keyResult.id || keyResult.metricId}>
                <KeyResultListItem
                  disabled={disabled}
                  keyResult={keyResult}
                  onChange={onChange}
                  onRemove={onRemove}
                />
              </div>
            );
          })}
          {!disabled && Object.values(keyResults).length === 0 && (
            <li className="block">
              <FlexContainer justify="justify-start" alignItems="items-center">
                <KeyResultSelector
                  label="Choose result"
                  filterIds={Object.values(keyResults).map(
                    ({ metricId }) => metricId
                  )}
                  onSelect={handleAddKeyResult}
                />
              </FlexContainer>
            </li>
          )}
        </ul>
      </div>
      {!disabled && Object.values(keyResults).length > 0 && (
        <FlexContainer marginTop="mt-5">
          <KeyResultSelector
            label="Choose results"
            filterIds={Object.values(keyResults).map(
              ({ metricId }) => metricId
            )}
            onSelect={handleAddKeyResult}
          />
        </FlexContainer>
      )}
    </div>
  );
};
