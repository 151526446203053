import {
  ChartSquareBarIcon,
  CursorClickIcon,
  PuzzleIcon,
  StarIcon,
  VariableIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { RoutePath } from "routes/RoutePath";

type AppSidebarProps = {};

export const SettingsSidebar = (props: AppSidebarProps) => {
  const subNavigation = [
    {
      name: "Key metrics",
      description: "Manage your key metrics.",
      to: RoutePath.settings.coreMetrics,
      icon: CursorClickIcon,
    },
    {
      name: "Key results",
      description: "Manage results used to measure project performance.",
      to: RoutePath.settings.kpis,
      icon: ChartSquareBarIcon,
    },
    {
      name: "Objectives",
      description: "Define and manage your objectives.",
      to: RoutePath.settings.objectives,
      icon: StarIcon,
    },
    {
      name: "Solutions",
      description: "Manage available solutions for your policies.",
      to: RoutePath.settings.interventions,
      icon: PuzzleIcon,
    },
  ];
  return (
    <nav
      aria-label="Sections"
      className="flex-shrink-0 w-80 border-r border-blue-gray-200"
    >
      <div className="flex-1 min-h-0 overflow-y-auto">
        {subNavigation.map((item) => (
          <NavLink
            key={item.to}
            to={item.to}
            className={({ isActive }) => {
              return classNames(
                isActive
                  ? "bg-blue-50 bg-opacity-50"
                  : "hover:bg-blue-50 hover:bg-opacity-50",
                "flex p-6 border-b border-blue-gray-200 last:border-none"
              );
            }}
          >
            <item.icon
              className="flex-shrink-0 -mt-0.5 h-6 w-6 text-blue-gray-400"
              aria-hidden="true"
            />
            <div className="ml-3 text-sm">
              <p className="font-medium text-blue-gray-900">{item.name}</p>
              <p className="mt-1 text-blue-gray-500">{item.description}</p>
            </div>
          </NavLink>
        ))}
      </div>
    </nav>
  );
};
