import React, { ChangeEvent, FormEvent, useState } from "react";
import { useModal } from "hooks/useModal";
import {
  InputGroup,
  PrimaryButton,
  TextInput,
  WhiteButton,
} from "components/shared";
import { useDispatch } from "store";
import { createSolution, openPopupFlag } from "store/reducers";
import { CreateSolutionRequest } from "types";
import { Modal } from "components/shared/modals";

type CreateSolutionModalProps = {
  onSuccess?: () => void;
};

export const CreateSolutionModal = ({
  onSuccess,
}: CreateSolutionModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [values, setValues] = useState<CreateSolutionRequest>({
    name: "",
    description: "",
    coreMetricIDs: [],
    capexImpact: undefined,
    costImpact: undefined,
    effectiveImpact: undefined,
  });

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    dispatch(createSolution(values))
      .unwrap()
      .then((data) => {
        setValues(data);
        dispatch(
          openPopupFlag({
            appearance: "success",
            title: "Solution created",
          })
        );
      });
    closeModal();
    onSuccess?.();
  };

  const handleChange = (name: keyof CreateSolutionRequest) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      setValues({
        ...values,
        [name]: event.target.value,
      });
    };
  };

  return (
    <div className="p-4 h-screen w-screen flex flex-col items-center justify-center">
      <form onSubmit={handleSubmit} className="px-1">
        <Modal>
          <Modal.Header title="Create a solution" onClose={closeModal} />
          <div className="flex flex-col flex-1">
            <InputGroup label="Name">
              <TextInput
                name="name"
                placeholder="Enter solution name"
                value={values.name}
                onChange={handleChange("name")}
              />
            </InputGroup>
            <InputGroup label="Description">
              <TextInput
                name="description"
                placeholder="Enter description of the solution"
                value={values.description}
                onChange={handleChange("description")}
              />
            </InputGroup>
          </div>
          <Modal.Footer>
            <PrimaryButton type="submit">Create</PrimaryButton>
            <WhiteButton onClick={closeModal}>Cancel</WhiteButton>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
};
