import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CreateMetricRequest,
  DataResponse,
  Metric,
  UpdateKeyPerformanceMetricRequest,
} from "types";
import { API } from "lib/API";

export const fetchMetrics = createAsyncThunk(
  "metrics/list",
  async (): Promise<Metric[]> => {
    const { data } = await API.GET<DataResponse<Metric[]>>("/metrics", {
      pageLimit: 10_000,
    });
    return data;
  }
);

export const createMetric = createAsyncThunk(
  "metrics/create",
  async (request: CreateMetricRequest): Promise<Metric> => {
    const { data } = await API.POST<CreateMetricRequest, DataResponse<Metric>>(
      "/metrics",
      request
    );
    return data;
  }
);

export const updateMetric = createAsyncThunk(
  "metrics/update",
  async (request: UpdateKeyPerformanceMetricRequest): Promise<Metric> => {
    const { data } = await API.PUT<
      UpdateKeyPerformanceMetricRequest,
      DataResponse<Metric>
    >(`/metrics/${request.id}`, request);
    return data;
  }
);
