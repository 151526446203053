import { useSelector } from "store";
import { generatePath, Link } from "react-router-dom";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import classNames from "classnames";
import { RoutePath } from "routes/RoutePath";
import { selectPolicyByID } from "store/reducers";
import { ComponentAppearance, UUID } from "types";
import {
  PolicyTableRowDropdown,
  FlexContainer,
  LineChart,
  PolicyCode,
  PolicySentence,
  PolicyTableSolutionPopover,
  GreenPill,
  PolicySimulationBar,
  Pill,
} from "components/shared";
dayjs.extend(localizedFormat);

type PolicyTableRowProps = {
  policyId: UUID;
  appearance?: ComponentAppearance;
};

export const PolicyTableRow = ({
  policyId,
  appearance = "full",
}: PolicyTableRowProps) => {
  const entity = useSelector(selectPolicyByID(policyId));

  if (!entity) {
    return null;
  }

  const renderChart = () => {
    return (
      <td
        className={classNames(
          appearance === "compact" ? "px-1 pl-0" : "px-6",
          "text-sm text-gray-500 font-medium"
        )}
      >
        <div className="flex items-center space-x-2">
          <div className="flex flex-shrink-0 -space-x-1 w-24">
            <LineChart
              appearance={appearance}
              decreasing={entity.deltaDirection === "decrease"}
            />
          </div>
        </div>
      </td>
    );
  };

  const dateActive = dayjs(entity.dateStart).format("LL");
  const dateEnd = dayjs(entity.dateEnd).format("LL");
  return (
    <tr key={entity.id}>
      {renderChart()}
      <td
        className={classNames(
          appearance === "compact" ? "px-1 pl-0" : "px-6 py-3",
          "max-w-0 w-full whitespace-pre-line text-sm"
        )}
      >
        <Link
          to={generatePath(RoutePath.policies.policy, {
            policyID: policyId,
          })}
        >
          <div className="flex items-center space-x-3">
            <span
              className={classNames(
                appearance === "compact"
                  ? "hover:opacity-75"
                  : "hover:text-gray-600",
                "cursor-pointer"
              )}
            >
              <FlexContainer marginBottom="mb-1" alignItems="items-center">
                <PolicyCode code={entity.code} />
                <div className="ml-2">
                  <GreenPill
                    label={entity.status === "done" ? "Successful" : "On track"}
                  />
                </div>
                <div className="ml-2">
                  <Pill
                    backgroundColor="bg-gold-700"
                    textColor="text-white"
                    label={`Since ${dayjs(entity.dateStart).format("LL")}`}
                  />
                </div>
              </FlexContainer>
              <PolicySentence policy={entity} />
            </span>
          </div>
          <PolicySimulationBar policyId={policyId} />
        </Link>
      </td>
      {appearance !== "compact" && (
        <>
          {entity.initiatives && (
            <>
              {entity.initiatives.length === 0 ? (
                <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-300">
                  No solutions
                </td>
              ) : (
                <td className="px-6 py-3 whitespace-nowrap text-sm text-gold-700 cursor-pointer">
                  <PolicyTableSolutionPopover policyId={policyId}>
                    {entity.initiatives.length} solution
                    {entity.initiatives.length > 1 ? "s" : ""}
                  </PolicyTableSolutionPopover>
                </td>
              )}
            </>
          )}
          <td className="px-6 py-3 text-sm text-gray-500">
            <FlexContainer direction="flex-col">
              <div className="whitespace-nowrap">Created: {dateActive}</div>
              <div className="whitespace-nowrap">Ends: {dateEnd}</div>
            </FlexContainer>
          </td>
          <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
            <PolicyTableRowDropdown policyId={policyId} />
          </td>
        </>
      )}
    </tr>
  );
};
